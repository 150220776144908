import React from "react";
import PropTypes from "prop-types";
import Features from "./Features";
//import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const ServiceCategories = ({ gridItems }) => (
    gridItems.map((item) => (
      <div>
        <h4 className="has-text-weight-semibold is-size-3">
          {item.heading}
        </h4>
        <p>{item.description}</p>
        <Features gridItems={item.services} />
      </div>
    ))
);

ServiceCategories.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
      services: PropTypes.array,
    })
  ),
};

export default ServiceCategories;
